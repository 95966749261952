//--------------
//  Terms Layout
//--------------

// PACKAGES
import React from "react"
import Helmet from "react-helmet"

// COMPONENT BUILD
const Blog  = ({ children }) => {
    // JSX TEMPLATE
    return (
        <>
            <Helmet>
                <title>House manual: What is it and why you need one - Informeasy</title>
                <html lang='en' />
                <link rel="canonical" href='https://www.informeasy.com/blog/home-guide' />
                <meta name="viewport" content="initial-scale=1, shrink-to-fit=no, user-scalable=no, width=device-width"/>
                <noscript>Please eneable Javascript or use a modern web browser!</noscript>
                <meta name="description" content='Running a vacation rental can become a full-time job. Could you imagine having more free time? The solution is literally in your hands. A house manual is a printed or digital collection of information that helps your guests orientate inside and around your property. It provides all the necessary information just when your guests need it.'></meta>
                <meta property="fb:app_id" content="2606230309647785" />
                <meta property="fb:admins" content="3029719147038958" />
                <meta
                    property="og:url"
                    content="https://ie-base-code.netlify.com/blog/home-guide"
                />
            </Helmet>
            {children}
        </>
    )
}

export default Blog

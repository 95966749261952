//---------------
// Blog Post
//---------------

// PACKAGES
import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Blog from "../layout/Blog"
import {
    FacebookShareButton,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share"

// COMPONENTS
import Image from "../components/atoms/Image" // Add images by passing files to the Image component instances with  named graphql querys
import Footer from "../components/organisms/footer/Footer"

//SVG
import Logo from "../assets/logos/ie-base-code.svg"
import Facebook from "../assets/icons/facebook.inline.svg"
import Twitter from "../assets/icons/twitter.inline.svg"
import Whatsapp from "../assets/icons/whatsapp.inline.svg"
import Pinterest from "../assets/icons/pinterest.inline.svg"
import Paperplane from "../assets/icons/paperplane.inline.svg"

// DATA QUERY
export const query = graphql`
query ($slug: String!) {
    Image1: file(relativePath: { eq: "nora.png" }) {
        childImageSharp {
            fluid(maxHeight: 150) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      frontmatter {
        title
        cover {
          childImageSharp {
            fluid(maxHeight: 1200) {
                ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        author
      }
      html
    }
  }
`

// COMPONENT BUILD
const BlogPost = props => {
    // JSX TEMPLATE
    return (
        <Blog>
        <section classNam="px-5 pb-5">
            <nav
                style={{ zIndex: `1000` }}
                className="ml-3 ml-md-8 ml-lg-10 ml-xl-12 py-2 py-md-3 position-absolute">
                <Link to="/">
                    <img className="logo-size" src={Logo} alt="Informeasy" />
                </Link>
            </nav>
            <main className="position-relative">

            {/* HEADER --> */}
                <div className="container-fluid">
                    <div className="row d-block">
                        <div className="px-0 px-xl-9">
                            <Image
                                placeholderStyle={{
                                    filter: `blur(5px)`,
                                }}
                                className="cover d-block position-relative"
                                source={
                                    props.data.markdownRemark.frontmatter.cover.childImageSharp.fluid
                                }
                                altText={"House-manual-booklet"}
                                style={{width:`100%`}}
                            />
                        </div>
                    </div>
                </div>

                {/* BODY --> */}
                    <div
                        className="container position-relative mt-n4 mt-md-n8"
                        style={{ zIndex: `100` }}>
                        <div className="row d-flex flex-row mx-0">
                            <div className="d-none d-md-flex col-12 col-md-1 pl-lg-4 pl-0 pr-0 pt-12 flex-row flex-md-column justify-content-center justify-content-md-start">
                                <FacebookShareButton
                                    className="mb-3"
                                    url={
                                        "https://informeasy.com/blog/home-guide"
                                    }>
                                    <Facebook
                                        className="shrink-icon"
                                        height="42px"
                                    />
                                </FacebookShareButton>
                                <TwitterShareButton
                                    className="mb-3"
                                    title={
                                        "Home Guide: What Is It And Why You Need One"
                                    }
                                    url={
                                        "https://informeasy.com/blog/home-guide"
                                    }>
                                    <Twitter
                                        className="shrink-icon"
                                        height="42px"
                                    />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                    className="mb-3"
                                    title={
                                        "Home Guide: What Is It And Why You Need One"
                                    }
                                    url={
                                        "https://informeasy.com/blog/home-guide"
                                    }
                                    separator={" 👉 "}
                                    col-12
                                    bg-white
                                    px-0
                                    position-relative
                                    windowHeight={600}
                                    windowWidth={800}>
                                    <Whatsapp
                                        className="shrink-icon"
                                        height="42px"
                                    />
                                </WhatsappShareButton>
                                <PinterestShareButton
                                    className="mb-3"
                                    title={
                                        "Home Guide: What Is It And Why You Need One"
                                    }
                                    media={
                                        "https://informeasy.com/uploads/booklet-iphone-blog.png"
                                    }
                                    url={
                                        "https://informeasy.com/blog/home-guide"
                                    }
                                    description={
                                        "If you run an Airbnb you probably know what a House Manual is. You might even know what a Welcome Letter is. Now, imagine just by combining those two you can take guest experience to the next level! In this post I’m about to discuss with you something fairly new but a rather handy tool that comes in a printed booklet format. Let’s call this a Home Guide."
                                    }>
                                    <Pinterest
                                        className="shrink-icon"
                                        height="42px"
                                    />
                                </PinterestShareButton>
                            </div>
                            <div className="col-12 col-md-10  bg-white px-0 position-relative">
                                <div
                                    className="d-block flex-column justify-content-center align-items-center"
                                    style={{
                                        zIndex: `100`,
                                        marginTop: `-44px`,
                                    }}>
                                    <div
                                        className="mx-auto mb-3 rounded-circle avatar shadow"
                                        style={{
                                                width: `80px`,
                                                borderWidth: `3px`,
                                                borderStyle: `solid`,
                                                borderColor: `white`,
                                            }}>
                                        <Image
                                            placeholderStyle={{
                                                filter: `blur(5px)`,
                                            }}
                                            source={props.data.Image1.childImageSharp.fluid}
                                            altText={"Author - Nora Budavari"}
                                            className="rounded-circle avatar"
                                        />
                                    </div>
                                    <div className="d-flex flex-column text-center">
                                        <h5 className="mb-1 mt-0 d-inline-block text-ie-black header-sans">
                                            {
                                                props.data.markdownRemark.frontmatter.author
                                            }
                                        </h5>
                                        <p className="d-inline-block text-ie-black font-italic text-muted">
                                            19 minute read
                                        </p>
                                    </div>
                                </div>
                                <div className="d-block d-md-none col-12 col-md-1 pl-lg-4 pl-0 pr-0 pt-3 d-flex flex-row flex-md-column justify-content-center justify-content-md-start">
                                    <FacebookShareButton
                                        className="mr-3"
                                        url={
                                            "https://informeasy.com/blog/home-guide"
                                        }>
                                        <Facebook height="42px" />
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        className="mr-3"
                                        title={
                                            "Home Guide: What Is It And Why You Need One"
                                        }
                                        url={
                                            "https://informeasy.com/blog/home-guide"
                                        }>
                                        <Twitter height="42px" />
                                    </TwitterShareButton>
                                    <WhatsappShareButton
                                        className="mr-3"
                                        title={
                                            "Home Guide: What Is It And Why You Need One"
                                        }
                                        url={
                                            "https://informeasy.com/blog/home-guide"
                                        }
                                        separator={" 👉 "}
                                        col-12
                                        bg-white
                                        px-0
                                        position-relative
                                        windowHeight={600}
                                        windowWidth={800}>
                                        <Whatsapp height="42px" />
                                    </WhatsappShareButton>
                                    <PinterestShareButton
                                        className="mr-3"
                                        title={
                                            "Home Guide: What Is It And Why You Need One"
                                        }
                                        media={
                                            "https://informeasy.com/uploads/booklet-iphone-blog.png"
                                        }
                                        url={
                                            "https://informeasy.com/blog/home-guide"
                                        }
                                        description={
                                            "If you run an Airbnb you probably know what a House Manual is. You might even know what a Welcome Letter is. Now, imagine just by combining those two you can take guest experience to the next level! In this post I’m about to discuss with you something fairly new but a rather handy tool that comes in a printed booklet format. Let’s call this a Home Guide."
                                        }>
                                        <Pinterest height="42px" />
                                    </PinterestShareButton>
                                </div>
                                <article className="col-12 col-md-10 mx-auto mb-8 px-0 px-md-5 py-6 text-center bg-white">
                                    <div className="border-bottom mb-6 pb-5">
                                        <h1 className="mb-5 masthead-title">
                                            {
                                                props.data.markdownRemark.frontmatter.title
                                            }
                                        </h1>
                                    </div>
                                    <div
                                        className="text-left mb-7"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                props.data.markdownRemark.html,
                                        }}></div>
                                    <div className="col-12 px-0 d-flex justify-content-start align-items-center mb-8">
                                    <div
                                        className="rounded-circle avatar shadow"
                                        style={{
                                                width: `80px`,
                                                borderWidth: `3px`,
                                                borderStyle: `solid`,
                                                borderColor: `white`,
                                            }}>
                                        <Image
                                            placeholderStyle={{
                                                filter: `blur(5px)`,
                                            }}
                                            source={props.data.Image1.childImageSharp.fluid}
                                            altText={"Author - Nora Budavari"}

                                        />
                                    </div>
                                        <div className="d-flex flex-column text-left ml-4">
                                            <h5 className="mb-1 mt-0 mr-3 d-inline-block text-ie-black header-sans">
                                                {
                                                    props.data.markdownRemark.frontmatter.author
                                                }
                                            </h5>
                                            <p className="d-inline-block text-ie-black font-italic mb-0">
                                                Co-founder and writer at Informeasy.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 px-0 pt-4 pb-8 p-md-6 mb-8 d-flex flex-column justify-content-center frame">
                                        <p className="mb-5 text-center lead lead-small text-muted font-italic">
                                            Was this article helpful?
                                            <br />
                                            Help other hosts by sharing with them!
                                        </p>
                                        <div>
                                            <FacebookShareButton
                                                className="mr-3"
                                                url={
                                                    "https://informeasy.com/blog/home-guide"
                                                }>
                                                <Facebook height="42px" />
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                className="mr-3"
                                                title={
                                                    "Home Guide: What Is It And Why You Need One"
                                                }
                                                url={
                                                    "https://informeasy.com/blog/home-guide"
                                                }>
                                                <Twitter height="42px" />
                                            </TwitterShareButton>
                                            <WhatsappShareButton
                                                className="mr-3"
                                                title={
                                                    "Home Guide: What Is It And Why You Need One"
                                                }
                                                url={
                                                    "https://informeasy.com/blog/home-guide"
                                                }
                                                separator={" 👉 "}
                                                windowHeight={600}
                                                windowWidth={800}>
                                                <Whatsapp height="42px" />
                                            </WhatsappShareButton>
                                            <PinterestShareButton
                                                title={
                                                    "Home Guide: What Is It And Why You Need One"
                                                }
                                                media={
                                                    "https://informeasy.com/uploads/booklet-iphone-blog.png"
                                                }
                                                url={
                                                    "https://informeasy.com/blog/home-guide"
                                                }
                                                description={
                                                    "If you run an Airbnb you probably know what a House Manual is. You might even know what a Welcome Letter is. Now, imagine just by combining those two you can take guest experience to the next level! In this post I’m about to discuss with you something fairly new but a rather handy tool that comes in a printed booklet format. Let’s call this a Home Guide."
                                                }>
                                                <Pinterest height="42px" />
                                            </PinterestShareButton>
                                        </div>
                                        <Paperplane height="88px" />
                                    </div>
                                    {/* <div className="text-left mb-7">
                                        <h2>Leave a comment</h2>
                                        <p>
                                            We would love to hear your thoughts
                                            on this! Please make sure your
                                            comment is related to the topic.
                                        </p>
                                        <p>
                                            Comments are moderated and will only
                                            be made live if they add to the
                                            discussion in a constructive way. If
                                            you disagree with a point, be
                                            polite. This should be a
                                            conversation between professional
                                            people with the aim that we all
                                            learn. If your comment is about a
                                            typo, problem with the website or
                                            anything else, please use our{" "}
                                            <Link to="/contact">
                                                contact form
                                            </Link>
                                            .
                                        </p>
                                        <p>
                                            Let's start a personal and
                                            meaningful conversation!
                                        </p>
                                    </div> */}
                                </article>
                            </div>
                            <div className="col-md-1 d-none d-md-block px-0" />
                        </div>
                    </div>
                    {/* FOOTER --> */}
            </main>
            <Footer />
        </section>
        </Blog>
    )
}

export default BlogPost
